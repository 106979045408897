import React from "react";

import { Icon } from '@types';

const ShareLinkIcon: Icon = ({ fill = '#c4c4c4', title = 'share_link', ...props }) => (
<svg { ...props } id="fdb0f8c4-3df2-405a-ba23-95c6aefe8bb3" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="959" height="554.5" viewBox="0 0 959 554.5">
    <title>{ title }</title>
    <rect x="718" y="399.5" width="241" height="2" fill="#3f3d56" />
    <polygon points="771.744 381.531 778.744 418.531 793.744 422.531 789.744 378.531 771.744 381.531" fill="#a0616a" />
    <polygon points="855.744 381.531 862.744 418.531 877.744 422.531 873.744 378.531 855.744 381.531" fill="#a0616a" />
    <path d="M908.24409,584.2815s-16-1-16,4-8,22-8,22-6,20,10,18,26-20,26-20l-4-19Z" transform="translate(-120.5 -172.75)" fill="#2f2e41" />
    <path d="M988.383,586.12508s-10.67275-6.861-9.91041-2.287,1.52468,16.77146,6.86106,17.5338,21.34549,5.33638,22.10783,6.86106,19.05848,7.62339,19.05848-2.287-11.43509-17.5338-11.43509-17.5338l-12.95976-9.91041s-8.38573-.76234-9.91041,4.574S988.383,586.12508,988.383,586.12508Z" transform="translate(-120.5 -172.75)" fill="#2f2e41" />
    <path d="M887,343.75s-17-25-23-17,15,23,15,23Z" transform="translate(-120.5 -172.75)" fill="#a0616a" />
    <path d="M893,367.75c-1-1-19-18-19-18l13-9,15,15Z" transform="translate(-120.5 -172.75)" fill="#575a89" />
    <path d="M895.5,290.25s-13,1-12,12,9.5,47.5,9.5,47.5l5,10S916.5,302.25,895.5,290.25Z" transform="translate(-120.5 -172.75)" fill="#575a89" />
    <path d="M896.5,255.25l3.6,12h45.1l1.30005-13,3.9,13H956.2l1.30005-13,3.9,13h6.1l-5.73535-13.81683a111.387,111.387,0,0,1-6.04688-20.44233A38.51318,38.51318,0,0,0,918,202.25h0q-.79743,0-1.58691.0321c-25.27832,1.02491-41.46729,27.4964-31.76819,50.86237L890.5,267.25h4.80005Z" transform="translate(-120.5 -172.75)" fill="#2f2e41" />
    <path d="M896,359.75s-31,26-22,61,12,145,12,145l31-3v-138l43,82,7,59,35-6-16-72-30.76873-91.778L952,369.49409Z" transform="translate(-120.5 -172.75)" fill="#2f2e41" />
    <circle cx="797" cy="62.5" r="21" fill="#a0616a" />
    <path d="M905,249.75s9,33,4,37,35-13,35-13-17-19-15-27Z" transform="translate(-120.5 -172.75)" fill="#a0616a" />
    <path d="M942,266.75s-16.01139,13.77211-31.5057,14.88605c0,0-20.4943,11.11395-19.4943,12.11395s4,15,4,15,5,7.72118,5,24c0,2-7,30-7,30l59,8s16-52,17-70,7-37-2-38S942,266.75,942,266.75Z" transform="translate(-120.5 -172.75)" fill="#575a89" />
    <path d="M965,266.75s7-6,11,8,20,58,16,67-28,49-28,49l-12-8,24-45-14-32Z" transform="translate(-120.5 -172.75)" fill="#575a89" />
    <path d="M957,381.75s-31,19-21,23,27-17,27-17Z" transform="translate(-120.5 -172.75)" fill="#a0616a" />
    <polygon points="774 38.5 774 59.5 782 59.5 785 50.5 785.75 59.5 808 59.5 811 50.5 811.75 59.5 821 59.5 821 38.5 774 38.5" fill="#2f2e41" />
    <path d="M894.5,310.25s-2,20,1,24S894.5,310.25,894.5,310.25Z" transform="translate(-120.5 -172.75)" opacity="0.2" />
    <rect x="177" y="486.5" width="424" height="2" fill="#3f3d56" />
    <path d="M695.71,507.36,572.69,630.38a100.28643,100.28643,0,0,1-141.82,0c-1.66-1.66-3.26-3.37-4.78-5.13a96.84877,96.84877,0,0,1-6.99-9,98.77835,98.77835,0,0,1-15.84-38q-.855-4.455-1.29-9-.465-4.84506-.47-9.78a100.75451,100.75451,0,0,1,3.72-27.22q1.27506-4.575,2.99-9a100.066,100.066,0,0,1,22.66-34.69l53.67-53.67a14.99773,14.99773,0,0,1,21.21,21.21l-53.67,53.67a70.86742,70.86742,0,0,0-10.57,13.48,69.78657,69.78657,0,0,0-10.01,36.22,72.046,72.046,0,0,0,.67,9.78,68.42389,68.42389,0,0,0,1.86005,9A70.2693,70.2693,0,0,0,551.48,609.17l30.92-30.92,55-55,37.1-37.1a70.40164,70.40164,0,0,0,7.87-90.02,15.00468,15.00468,0,0,1,24.61-17.17A100.457,100.457,0,0,1,695.71,507.36Z" transform="translate(-120.5 -172.75)" fill="#3f3d56" />
    <path d="M888.5,273.03q0,1.11-.03,2.22a99.821,99.821,0,0,1-8.33,38,96.82561,96.82561,0,0,1-4.5,9,100.56063,100.56063,0,0,1-16.51,21.69l-53.67,53.67a14.99775,14.99775,0,0,1-21.21-21.21l53.67-53.67c.16-.16.32-.32.47-.48a69.874,69.874,0,0,0,20.07-47c.03-.74.04-1.48.04-2.22q0-3.42-.32-6.78a70.2081,70.2081,0,0,0-20.26-42.92c-1.07-1.07-2.16-2.1-3.29-3.08a70.28966,70.28966,0,0,0-92.82,0c-1.13.98-2.22,2.01-3.29,3.08L615.5,346.35a70.3745,70.3745,0,0,0,0,99.4,14.99773,14.99773,0,0,1-21.21,21.21,100.39527,100.39527,0,0,1,0-141.81995L717.31,202.12a100.38657,100.38657,0,0,1,156.21,18.13,96.266,96.266,0,0,1,4.97,9,99.43233,99.43233,0,0,1,9.78,37Q888.495,269.625,888.5,273.03Z" transform="translate(-120.5 -172.75)" fill="#3f3d56" />
    <path d="M245.8155,699.41567c-4.58109,16.93445-20.27235,27.42238-20.27235,27.42238s-8.26385-16.96824-3.68276-33.90269S242.13274,665.513,242.13274,665.513,250.39658,682.48122,245.8155,699.41567Z" transform="translate(-120.5 -172.75)" fill={ fill } />
    <path d="M204.14038,699.41567c4.58109,16.93445,20.27235,27.42238,20.27235,27.42238s8.26384-16.96824,3.68276-33.90269S207.82314,665.513,207.82314,665.513,199.55929,682.48122,204.14038,699.41567Z" transform="translate(-120.5 -172.75)" fill={ fill } />
    <path d="M210.872,695.77627c12.57057,12.237,14.106,31.048,14.106,31.048s-18.84552-1.029-31.41609-13.26592-14.106-31.048-14.106-31.048S198.30139,683.53933,210.872,695.77627Z" transform="translate(-120.5 -172.75)" fill="#3f3d56" />
    <path d="M239.08391,695.77627c-12.57057,12.237-14.106,31.048-14.106,31.048s18.84552-1.029,31.41609-13.26592,14.106-31.048,14.106-31.048S251.65448,683.53933,239.08391,695.77627Z" transform="translate(-120.5 -172.75)" fill="#3f3d56" />
    <path d="M803.5,271.25a20.24728,20.24728,0,0,1-.4,4,20.005,20.005,0,0,1-39.2,0,20.17518,20.17518,0,0,1,.23-9,20,20,0,0,1,39.37,5Z" transform="translate(-120.5 -172.75)" fill="#3f3d56" />
    <path d="M508.5,574.25a20.24728,20.24728,0,0,1-.4,4,20.005,20.005,0,0,1-39.2,0,20.17518,20.17518,0,0,1,.23-9,20,20,0,0,1,39.37,5Z" transform="translate(-120.5 -172.75)" fill="#3f3d56" />
    <rect y="552.5" width="231" height="2" fill="#3f3d56" />
    <path d="M699.18,220.25h42.63c-1.13.98-2.22,2.01-3.29,3.08l-5.92,5.92H690.18Z" transform="translate(-120.5 -172.75)" fill={ fill } />
    <path d="M878.49,229.25H843.21a70.16209,70.16209,0,0,0-5.29-5.92c-1.07-1.07-2.16-2.1-3.29-3.08h38.89A96.266,96.266,0,0,1,878.49,229.25Z" transform="translate(-120.5 -172.75)" fill={ fill } />
    <polygon points="532.68 93.5 575.1 93.5 566.1 102.5 523.68 102.5 532.68 93.5" fill={ fill } />
    <path d="M802.87,266.25a20.17518,20.17518,0,0,1,.23,9H763.9a20.17518,20.17518,0,0,1,.23-9Z" transform="translate(-120.5 -172.75)" fill={ fill } />
    <path d="M888.5,273.03q0,1.11-.03,2.22H858.46c.03-.74.04-1.48.04-2.22q0-3.42-.32-6.78h30.09Q888.495,269.625,888.5,273.03Z" transform="translate(-120.5 -172.75)" fill={ fill } />
    <polygon points="485.68 140.5 528.1 140.5 519.1 149.5 476.68 149.5 485.68 140.5" fill={ fill } />
    <path d="M880.14,313.25a96.82561,96.82561,0,0,1-4.5,9H838.39a69.43047,69.43047,0,0,0,7.49-9Z" transform="translate(-120.5 -172.75)" fill={ fill } />
    <path d="M441.51,523.25a69.76893,69.76893,0,0,0-4.57,9H405.22q1.27506-4.575,2.99-9Z" transform="translate(-120.5 -172.75)" fill={ fill } />
    <polygon points="559.32 350.5 550.32 359.5 507.9 359.5 516.9 350.5 559.32 350.5" fill={ fill } />
    <path d="M434.03,578.25H403.26q-.855-4.455-1.29-9h30.2A68.42389,68.42389,0,0,0,434.03,578.25Z" transform="translate(-120.5 -172.75)" fill={ fill } />
    <path d="M508.5,574.25a20.24728,20.24728,0,0,1-.4,4H468.9a20.17518,20.17518,0,0,1,.23-9h38.74A20.14631,20.14631,0,0,1,508.5,574.25Z" transform="translate(-120.5 -172.75)" fill={ fill } />
    <polygon points="513.32 396.5 504.32 405.5 461.9 405.5 470.9 396.5 513.32 396.5" fill={ fill } />
    <path d="M476.94,625.25H426.09a96.84877,96.84877,0,0,1-6.99-9h41.24A69.57992,69.57992,0,0,0,476.94,625.25Z" transform="translate(-120.5 -172.75)" fill={ fill } />
    <path d="M586.82,616.25l-9,9h-51.2a69.57992,69.57992,0,0,0,16.6-9Z" transform="translate(-120.5 -172.75)" fill={ fill } />
    <path d="M436.094,342.48439c-7.6454-12.91835-22.77119-13.5204-22.77119-13.5204s-14.73923-1.88484-24.19435,17.79c-8.81293,18.33853-20.97589,36.04476-1.95814,40.33768l3.43516-10.69175,2.12738,11.48773a74.41154,74.41154,0,0,0,8.137.13907c20.36647-.65755,39.76246.19238,39.13794-7.116C439.17756,371.1953,443.45037,354.91442,436.094,342.48439Z" transform="translate(-120.5 -172.75)" fill="#2f2e41" />
    <polygon points="260.5 455 261.5 481 279.5 481 278.5 453 260.5 455" fill="#fbbebe" />
    <path d="M430,443.75l-1.82,6.05L404.6,528.13l-2.6,8.62,2,92-25,3s-.73-9.66-1.8-23.92c-2.15-28.24-5.67-74.55-7.71-99.75-.75-9.32-1.29-15.75-1.49-17.33-1-8,12-48,12-48l26-2Z" transform="translate(-120.5 -172.75)" fill="#2f2e41" />
    <path d="M387,650.75s-7-8-8-2-13,29-13,29-35,23-5,25c0,0,15-2,20-10,0,0,8-5,10-5s14-4,12-10-1-28-1-28Z" transform="translate(-120.5 -172.75)" fill="#2f2e41" />
    <polygon points="265.5 431 274.5 448 288.5 441 279.5 421 265.5 431" fill="#fbbebe" />
    <path d="M379,554.75s-7.46-16.3-9.5-41.5c14.48-16.18,32-34,32-34l21-1,6.5-.5c1.52,15.2-11.34,34.78-24.4,50.38A279.192,279.192,0,0,1,379,554.75Z" transform="translate(-120.5 -172.75)" opacity="0.2" />
    <path d="M405,478.75s-64,56-55,79,34,51,34,51l19.31461-11.528L385,554.75s7.67809-6.80887,17.024-16.89766C417.23275,521.43475,436.85815,496.33148,435,477.75l-1-34-17,2Z" transform="translate(-120.5 -172.75)" fill="#2f2e41" />
    <path d="M399,616.75s-7,0-6,6,3,16,3,16-8,47,11,36,14-40,14-40,7-18-2-20-12-4-13-3S399,616.75,399,616.75Z" transform="translate(-120.5 -172.75)" fill="#2f2e41" />
    <circle cx="292.5" cy="181" r="18" fill="#fbbebe" />
    <path d="M406,360.75s8,21,5,22-9,6-9,6l10,9,26-14s-12-16-11-23Z" transform="translate(-120.5 -172.75)" fill="#fbbebe" />
    <path d="M438,379.75s-20.92467,10.315-28.46234,3.65748c0,0-12.53766-.65748-14.53766,6.34252s2,13-3,15-8.5,7.5-5.5,16.5,2.5,10.5-1.5,13.5-8,9-8,11-4,2-2,4-2,0,2,2,15-16,23-7,33,24,35,15,8-31,8-31,21-39,15-43S438,379.75,438,379.75Z" transform="translate(-120.5 -172.75)" fill="#cfcce0" />
    <path d="M435,449.75s-11-2-14,5-20,23-9,24,19-17,19-17l11-2Z" transform="translate(-120.5 -172.75)" fill="#fbbebe" />
    <path d="M446,385.75l13.07813.72835S465,392.75,466,398.75s20,51,8,56-38,8-38,8l-3-14,17-6-12-25Z" transform="translate(-120.5 -172.75)" fill="#cfcce0" />
    <polygon points="266 382.5 282.449 423.623 263 382.5 280 364.5 266 382.5" opacity="0.2" />
    <polygon points="256.143 427.766 263 436.5 258 436.5 256.143 427.766" opacity="0.2" />
    <circle cx="294.67402" cy="156.27942" r="10.93678" fill="#2f2e41" />
    <path d="M403.086,326.15133a10.9372,10.9372,0,0,1,9.78554-10.8753,11.04689,11.04689,0,0,0-1.15124-.06148,10.93678,10.93678,0,1,0,0,21.87355,11.04689,11.04689,0,0,0,1.15124-.06148A10.93719,10.93719,0,0,1,403.086,326.15133Z" transform="translate(-120.5 -172.75)" fill="#2f2e41" />
    <polygon points="312.214 168.489 297.27 160.662 276.634 163.864 272.364 182.721 282.993 182.312 285.962 175.384 285.962 182.198 290.866 182.009 293.712 170.98 295.491 182.721 312.925 182.365 312.214 168.489" fill="#2f2e41" />
</svg>
);

export default ShareLinkIcon;
