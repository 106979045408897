import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import SEO from '../components/seo';

import Layout from '@components/Layout';
import SeoServicesHero from '@sections/service/Service.Hero';
import SeoServicesIntroduction from '@sections/service/Service.Introduction';
import SeoServicesFeatures from '@sections/service/Service.Features';
import SeoServicesServices from '@sections/service/Service.Services';
import SeoServicesCTA from '@sections/CTA/CTA.Main';
// import SeoServicesStrategy from '@sections/service/Service.Strategy';

import '../components/Utils';

import { useThemeUI } from 'theme-ui';

// import Icons from '@icons';
import Segmentation from '@icons/undraw/Segmentation.Icon';
import Search from '@icons/undraw/Search.Icon';

import Blogging from '@icons/undraw/Blogging.Icon';
import SetupWizard from '@icons/undraw/SetupWizard.Icon';
import StatusUpdate from '@icons/undraw/StatusUpdate.Icon';
import SearchEngines from '@icons/undraw/SearchEngines.Icon';
import SuccessFactors from '@icons/undraw/SuccessFactors.Icon';
import NewIdeas from '@icons/undraw/NewIdeas.Icon';
import ShareLink from '@icons/undraw/ShareLink.Icon';

const SeoServicesPage: React.FC = () => {
    const intl = useIntl();
    const invertHeader = true;

    const context = useThemeUI();
    const { theme } = context;
    const fillColor = theme.colors.gradientBg;

    const HeroTranslations = {
        title: {
            text: intl.formatMessage({ id: 'seo-title' })
        },
        subtitle: {
            text: intl.formatMessage({ id: 'seo-subtitle' })
        },
        description: {
            text: intl.formatMessage({ id: 'seo-description' })
        },
        Link1: {
            text: intl.formatMessage({ id: 'seo-button-discover' }),
            link: '/seo-services/#work'
        },
        Link2: {
            text: intl.formatMessage({ id: 'seo-button-contact' }),
            link: '/contact/'
        },
        MainIcon: {
            Icon: Segmentation,
            fill: fillColor,
        }
    }

     const FeatureTranslations = {
        columns: 3,
        title: {
            text: intl.formatMessage({ id: 'seo-features-title' })
        },
        subtitle: {
            text: intl.formatMessage({ id: 'seo-features-subtitle' })
        },
        features: [
            {
                title: intl.formatMessage({ id: 'seo-features-1' }),
                description: intl.formatMessage({ id: 'seo-features-1-desc' }),
                Icon: Blogging,
                fill: fillColor
            },
            {
                title: intl.formatMessage({ id: 'seo-features-2' }),
                description: intl.formatMessage({ id: 'seo-features-2-desc' }),
                Icon: SetupWizard,
                fill: fillColor
            },
            {
                title: intl.formatMessage({ id: 'seo-features-3' }),
                description: intl.formatMessage({ id: 'seo-features-3-desc' }),
                Icon: SearchEngines,
                fill: fillColor
            },
            {
                title: intl.formatMessage({ id: 'seo-features-4' }),
                description: intl.formatMessage({ id: 'seo-features-4-desc' }),
                Icon: SuccessFactors,
                fill: fillColor
            },
            {
                title: intl.formatMessage({ id: 'seo-features-5' }),
                description: intl.formatMessage({ id: 'seo-features-5-desc' }),
                Icon: NewIdeas,
                fill: fillColor
            },
            {
                title: intl.formatMessage({ id: 'seo-features-6' }),
                description: intl.formatMessage({ id: 'seo-features-6-desc' }),
                Icon: ShareLink,
                fill: fillColor
            },
        ],
    };

    const ServicesTranslations = {
        title: {
            text: intl.formatMessage({ id: 'seo-services-title' })
        },
        typeformButton: {
            text: intl.formatMessage({ id: 'web-services-button' }),
        },
        services: [
            { 
                title: { text: intl.formatMessage({ id: 'seo-services-1' }) },
                description: { text: intl.formatMessage({ id: 'seo-services-1-desc' }) },
                Image: { name: 'google-analytics.jpg' },
                // Icon: ReactLogo
            },
            { 
                title: { text: intl.formatMessage({ id: 'seo-services-2' }) },
                description: { text: intl.formatMessage({ id: 'seo-services-2-desc' }) },
                Image: { name: 'diary-notebook.jpg' },
            },
            { 
                title: { text: intl.formatMessage({ id: 'seo-services-3' }) },
                description: { text: intl.formatMessage({ id: 'seo-services-3-desc' }) },
                Image: { name: 'meeting-board.jpg' },
            },
        ]
    };

    return (
    <Layout id="seo" invertHeader={invertHeader}>
        <SEO
            /*lang={intl.locale}*/
            lang={intl.formatMessage({ id: 'current' })}
            title={intl.formatMessage({ id: 'seo-meta-title' })}
            description={intl.formatMessage({ id: 'seo-meta-description'})}
            slug='/seo-services/'
        />
        <SeoServicesHero translations={ HeroTranslations } />
        <SeoServicesFeatures translations={ FeatureTranslations } />
        <SeoServicesServices translations={ ServicesTranslations } sectionContext={{ small: true, noPaddingTop: true, noPaddingBottom: true, }} />
        <SeoServicesCTA sectionContext={{ small: true, gradientBg: false }} />
        {/*<SeoServicesStrategy sectionContext={{ gradientBg: true, }}/>*/}
    </Layout>
    )
}

export default SeoServicesPage;
