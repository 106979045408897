import React from "react";

import { Icon } from '@types';

const BloggingIcon: Icon = ({ fill = '#c4c4c4', title = 'blogging', ...props }) => (
<svg { ...props } id="f505141c-f814-4615-8df2-52dee1c7b14b" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1050" height="805.83" viewBox="0 0 1050 805.83">
    <defs>
        <linearGradient id="5d79ebac-1fb9-494f-9c8c-41d6171d09f4" x1="756.96" y1="364.35" x2="756.96" y2="20.38" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="gray" stopOpacity="0.25" />
            <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
            <stop offset="1" stopColor="gray" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient id="3188e630-8ea4-4111-9509-7049548e0ddb" x1="919.02" y1="298.54" x2="919.02" y2="260.1" xlinkHref="#5d79ebac-1fb9-494f-9c8c-41d6171d09f4" />
        <linearGradient id="9ebb77c1-6d01-4561-8dcd-16473be994df" x1="532.43" y1="677.74" x2="532.43" y2="220.6" gradientTransform="matrix(-1, 0, 0, 1, 923.85, 0)" xlinkHref="#5d79ebac-1fb9-494f-9c8c-41d6171d09f4" />
        <linearGradient id="589be0bd-308d-435b-8cf0-b9d21bd74c82" x1="441.23" y1="805.83" x2="441.23" y2="578.64" xlinkHref="#5d79ebac-1fb9-494f-9c8c-41d6171d09f4" />
        <linearGradient id="3aa21ed4-97e3-4e91-81c1-d27551f27de1" x1="467.66" y1="592.77" x2="467.66" y2="390.97" xlinkHref="#5d79ebac-1fb9-494f-9c8c-41d6171d09f4" />
    </defs>
    <title>{ title }</title>
    <path d="M135.45,461.13c35.65,45.33,21.8,111.54-.85,164.58-7.59,17.76-16.16,36.54-13,55.58,3.91,23.28,24.69,40.31,46.51,49.33,39.77,16.45,87.37,12.53,123.92-10.21,31.58-19.65,54.2-51.6,86.32-70.35,53.76-31.38,122.18-19.53,180.55,2.09,41.3,15.29,86.45,35.18,127.48,19.2,28.87-11.25,48.83-38.62,59.71-67.64,5.25-14,9.06-29.17,19.09-40.28,6-6.6,13.74-11.29,21.71-15.24,72.77-36.08,164.67-16.76,235.35-56.77,47.73-27,78.29-78,91.82-131.16s12-108.9,8.52-163.64c-2.44-38.91-6.82-80.44-31.68-110.47-26.3-31.76-71.43-43.58-112.22-37.54s-77.77,27.56-110.3,52.9c-40.65,31.68-78.36,71.12-128.27,84-34,8.74-69.75,4-104.81,2.3-58.61-2.77-117.24,3.39-175.53,10.12-55.8,6.45-112,13.55-165.16,31.7C257,242.43,226.86,265.75,191.34,282c-23.15,10.62-48.4,13.55-70.33,27.08-27,16.66-51.76,45.64-44.84,79.55C82.75,420.9,116.53,437.07,135.45,461.13Z" transform="translate(-75 -47.08)" fill={ fill } opacity="0.1" />
    <path d="M264.78,659.29s-3.3-97.76-56.19-153c-22.2-23.18-36.78-52.62-40.64-84.48a249.26,249.26,0,0,1-1.24-45.57" transform="translate(-75 -47.08)" fill="none" stroke="#535461" strokeMiterlimit="10" strokeWidth="2" />
    <path d="M133.58,341.26c3.62,10.92,33.88,36,33.88,36s9.27-38.21,5.65-49.12a20.82,20.82,0,0,0-39.53,13.11Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M121,412.55c9,7.2,48,11.82,48,11.82s-13-37.11-22-44.3A20.82,20.82,0,0,0,121,412.55Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M156.65,515.4c11.38,1.68,47.41-14,47.41-14s-29.94-25.48-41.32-27.16a20.82,20.82,0,0,0-6.09,41.2Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M201.17,587.14c10.93,3.58,49.1-5.82,49.1-5.82s-25.21-30.17-36.14-33.75a20.82,20.82,0,0,0-13,39.57Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M211.17,412.72C205.86,422.92,172,442.9,172,442.9s-3.08-39.19,2.23-49.4a20.82,20.82,0,1,1,36.94,19.22Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M262.18,497.28c-8.12,8.15-46.4,17.07-46.4,17.07s8.79-38.32,16.9-46.47a20.82,20.82,0,1,1,29.5,29.39Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M300.48,586.27c-6.62,9.4-42.85,24.67-42.85,24.67s2.18-39.25,8.81-48.66a20.82,20.82,0,1,1,34,24Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M729.56,592.91s-15.88-5.38-23.52,8.61a25.52,25.52,0,0,0,.34,24.57c5,9.07,15.36,20.85,35,19.8C758,645,763.48,632.32,765,620.52a26.06,26.06,0,0,0-34.61-27.9Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M709.74,627.35a25.52,25.52,0,0,1-.34-24.57c7.64-14,23.52-8.61,23.52-8.61l.81-.29a25.83,25.83,0,0,1,18.66.42,25.84,25.84,0,0,0-22-2.1l-.81.29s-15.88-5.38-23.52,8.61a25.52,25.52,0,0,0,.34,24.57c3.31,6,8.92,13.11,18,17A39.35,39.35,0,0,1,709.74,627.35Z" transform="translate(-75 -47.08)" fill="#fff" opacity="0.1" />
    <path d="M721.36,599.64s5.89,4.63,20.18,0" transform="translate(-75 -47.08)" opacity="0.2" />
    <path d="M720.1,566s10.09,27.33,9.25,34.48l8-.84s-13.46-24-10.09-33.64Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M720.1,566s10.09,27.33,9.25,34.48l8-.84s-13.46-24-10.09-33.64Z" transform="translate(-75 -47.08)" opacity="0.2" />
    <path d="M761.29,568.28s-15.88-8.12-23-1.41-8.2,23.86-8.2,23.86S756,594.29,761.29,568.28Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M761.29,568.28s-15.88-8.12-23-1.41-8.2,23.86-8.2,23.86S756,594.29,761.29,568.28Z" transform="translate(-75 -47.08)" fill="#fff" opacity="0.1" />
    <path d="M730.1,590.73s4.17-19.91,31.19-22.45" transform="translate(-75 -47.08)" fill="none" stroke={ fill } strokeMiterlimit="10" />
    <path d="M273.63,471.59s3.24-52.86-22.43-85.34a80.24,80.24,0,0,1-17.57-47.62A135,135,0,0,1,235.31,314" transform="translate(-75 -47.08)" fill="none" stroke="#535461" strokeMiterlimit="10" strokeWidth="2" />
    <path d="M219.26,293.44c1.39,6.07,16.41,21.15,16.41,21.15s7-20.12,5.57-26.18a11.27,11.27,0,1,0-22,5Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M208.8,331.22c4.47,4.34,25.27,8.84,25.27,8.84s-5.09-20.67-9.56-25a11.27,11.27,0,1,0-15.71,16.17Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M222.75,388.49c6,1.49,26.27-5.13,26.27-5.13s-14.83-15.27-20.87-16.76a11.27,11.27,0,0,0-5.4,21.89Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M243.06,429.43c5.71,2.49,26.76-.62,26.76-.62s-12-17.56-17.74-20a11.27,11.27,0,1,0-9,20.66Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M257.41,335.95c-3.38,5.23-22.66,14.25-22.66,14.25s.35-21.28,3.74-26.51a11.27,11.27,0,0,1,18.92,12.26Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M280.56,384.14c-4.79,4-25.89,6.82-25.89,6.82s6.7-20.2,11.5-24.18a11.27,11.27,0,0,1,14.39,17.36Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M296.63,434.07c-4.05,4.73-24.36,11.1-24.36,11.1s3.19-21,7.25-25.77a11.27,11.27,0,0,1,17.12,14.68Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M219.26,293.44c1.39,6.07,16.41,21.15,16.41,21.15s7-20.12,5.57-26.18a11.27,11.27,0,1,0-22,5Z" transform="translate(-75 -47.08)" fill="#fff" opacity="0.5" />
    <path d="M208.8,331.22c4.47,4.34,25.27,8.84,25.27,8.84s-5.09-20.67-9.56-25a11.27,11.27,0,1,0-15.71,16.17Z" transform="translate(-75 -47.08)" fill="#fff" opacity="0.5" />
    <path d="M222.75,388.49c6,1.49,26.27-5.13,26.27-5.13s-14.83-15.27-20.87-16.76a11.27,11.27,0,0,0-5.4,21.89Z" transform="translate(-75 -47.08)" fill="#fff" opacity="0.5" />
    <path d="M243.06,429.43c5.71,2.49,26.76-.62,26.76-.62s-12-17.56-17.74-20a11.27,11.27,0,1,0-9,20.66Z" transform="translate(-75 -47.08)" fill="#fff" opacity="0.5" />
    <path d="M257.41,335.95c-3.38,5.23-22.66,14.25-22.66,14.25s.35-21.28,3.74-26.51a11.27,11.27,0,0,1,18.92,12.26Z" transform="translate(-75 -47.08)" fill="#fff" opacity="0.5" />
    <path d="M280.56,384.14c-4.79,4-25.89,6.82-25.89,6.82s6.7-20.2,11.5-24.18a11.27,11.27,0,0,1,14.39,17.36Z" transform="translate(-75 -47.08)" fill="#fff" opacity="0.5" />
    <path d="M296.63,434.07c-4.05,4.73-24.36,11.1-24.36,11.1s3.19-21,7.25-25.77a11.27,11.27,0,0,1,17.12,14.68Z" transform="translate(-75 -47.08)" fill="#fff" opacity="0.5" />
    <polygon points="504.5 20.39 504.5 37.74 504.5 364.35 1009.42 364.35 1009.42 37.74 1009.42 20.39 504.5 20.39" fill="url(#5d79ebac-1fb9-494f-9c8c-41d6171d09f4)" />
    <rect x="511.99" y="35.99" width="492.08" height="322.13" fill="#fff" />
    <rect x="511.99" y="20.67" width="492.08" height="23.7" fill="#eee" />
    <circle cx="530.71" cy="32.16" r="5.75" fill="#ff5252" />
    <circle cx="546.52" cy="32.16" r="5.75" fill="#ff0" />
    <circle cx="562.32" cy="32.16" r="5.75" fill="#69f0ae" />
    <rect x="530.85" y="82.33" width="183.37" height="7.64" fill={ fill } opacity="0.5" />
    <rect x="530.85" y="97.61" width="183.37" height="7.64" fill={ fill } opacity="0.5" />
    <rect x="530.85" y="112.89" width="183.37" height="7.64" fill={ fill } opacity="0.5" />
    <rect x="594.05" y="128.17" width="120.16" height="7.64" fill={ fill } opacity="0.5" />
    <rect x="530.85" y="143.46" width="96.55" height="7.64" fill={ fill } opacity="0.5" />
    <rect x="582.94" y="158.74" width="74.32" height="7.64" fill={ fill } opacity="0.5" />
    <rect x="738.53" y="135.82" width="216.02" height="124.33" fill={ fill } opacity="0.5" />
    <rect x="764.23" y="147.62" width="216.02" height="124.33" fill="#eee" />
    <path d="M960.09,319H839.23V296.26C868.76,282.46,914.19,274.71,960.09,319Z" transform="translate(-75 -47.08)" fill={ fill } />
    <g opacity="0.1">
        <path d="M1053.86,315.78V319H903.13v-.69S977.3,246.13,1053.86,315.78Z" transform="translate(-75 -47.08)" />
    </g>
    <path d="M1055.25,315.78V319H904.52v-.69S978.69,246.13,1055.25,315.78Z" transform="translate(-75 -47.08)" fill={ fill } />
    <circle cx="789.24" cy="169.85" r="11.11" fill={ fill } />
    <path d="M958.85,208.51c-1.27,0-2.69,0-3.65.08a4.44,4.44,0,0,0-1.27.26l-6.54,2.35c5.28.13,10.79.09,16.19,0l30.34-.23c3.09,0,6.35-.05,8.93-.24,1.73-.12,3-.31,4.59-.45,5.74-.51,14.12-.41,21.6-.39s16.51-.17,19.14-.92h-16.05c-1.73,0-3.55,0-5.08-.09-1.79-.1-2.88-.3-4.45-.44-2.6-.22-6.18-.25-9.52-.26C994.8,208.17,976.73,208.61,958.85,208.51Z" transform="translate(-75 -47.08)" fill="#f4f4f4" />
    <path d="M976.86,222c-.28,0-.58,0-.79,0a1,1,0,0,0-.27.06l-1.42.51c1.14,0,2.34,0,3.51,0l6.57-.05c.67,0,1.37,0,1.93-.05l1-.1c1.24-.11,3.06-.09,4.68-.08a25.5,25.5,0,0,0,4.15-.2h-3.48c-.38,0-.77,0-1.1,0s-.62-.07-1-.09c-.56,0-1.34-.05-2.06-.06C984.64,221.92,980.73,222,976.86,222Z" transform="translate(-75 -47.08)" fill="#f4f4f4" />
    <path d="M930.32,241.44c-.28,0-.58,0-.79,0a1,1,0,0,0-.27.06l-1.42.51c1.14,0,2.34,0,3.51,0l6.57-.05c.67,0,1.37,0,1.93-.05l1-.1c1.24-.11,3.06-.09,4.68-.08a25.5,25.5,0,0,0,4.15-.2h-3.48c-.38,0-.77,0-1.1,0s-.62-.07-1-.09c-.56,0-1.34-.05-2.06-.06C938.11,241.37,934.19,241.46,930.32,241.44Z" transform="translate(-75 -47.08)" fill="#f4f4f4" />
    <path d="M904.18,312.87H899s-1.63-57.12,5.22-74.75h1.31S900.92,302.76,904.18,312.87Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M900.59,235.19s-4.24,11.75-2.28,17c0,0,2.94-10.77,6.37-12.4s7,12.4,7,12.4,2-8.81-1.63-16a28.38,28.38,0,0,1,10.77,11.42s.65-4.9-7.83-15c0,0,7.51,2,12.4,6.53,0,0,1.63-3.59-8.49-9.47,0,0,1.31-4.24,9.14-2.28,0,0,0-5.55-12.4-4.57,0,0,0-3.59,8.16-2.94,0,0,0-4.57-9.47-2.94,0,0,2-2.94,3.26-3.59a6.56,6.56,0,0,0-4.57,1.31s.33-5.88-4.57-8.16l-.65,8.16s-5.49-6.47-4.24-5.22a14.32,14.32,0,0,1,1,6.2s-5.55-4.57-12.4.33c0,0,8.49.65,9.79,3.59,0,0-7.18-1.31-8.81-.33,0,0,5.55,2,5.22,3.26,0,0-9.79,0-11.1,3.59,0,0,10.12,1.31,10.77,2.28,0,0-11.75,8.49-12.4,10.77,0,0,12.08-7.18,14-7.18s-8.81,10.45-8.16,13.06C889.5,245,898.31,234.53,900.59,235.19Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M968.61,310.74h4s1.25-43.64-4-57.11h-1S971.1,303,968.61,310.74Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M971.35,251.38s3.24,9,1.75,13c0,0-2.24-8.23-4.86-9.48s-5.36,9.48-5.36,9.48-1.5-6.73,1.25-12.22a21.68,21.68,0,0,0-8.23,8.73s-.5-3.74,6-11.47c0,0-5.74,1.5-9.48,5,0,0-1.25-2.74,6.48-7.23,0,0-1-3.24-7-1.75,0,0,0-4.24,9.48-3.49,0,0,0-2.74-6.23-2.24,0,0,0-3.49,7.23-2.24a11,11,0,0,0-2.49-2.74,5,5,0,0,1,3.49,1s-.25-4.49,3.49-6.23l.5,6.23s4.19-4.94,3.24-4a10.94,10.94,0,0,0-.75,4.74s4.24-3.49,9.48.25c0,0-6.48.5-7.48,2.74,0,0,5.49-1,6.73-.25,0,0-4.24,1.5-4,2.49,0,0,7.48,0,8.48,2.74,0,0-7.73,1-8.23,1.75,0,0,9,6.48,9.48,8.23,0,0-9.23-5.49-10.72-5.49s6.73,8,6.23,10C979.83,258.86,973.1,250.88,971.35,251.38Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M1016.35,302.21h2.47s.77-27-2.47-35.37h-.62S1017.89,297.42,1016.35,302.21Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M1018,265.45s2,5.56,1.08,8c0,0-1.39-5.1-3-5.87s-3.32,5.87-3.32,5.87a12.9,12.9,0,0,1,.77-7.57,13.43,13.43,0,0,0-5.1,5.41s-.31-2.32,3.71-7.1a15.67,15.67,0,0,0-5.87,3.09s-.77-1.7,4-4.48c0,0-.62-2-4.32-1.08,0,0,0-2.63,5.87-2.16,0,0,0-1.7-3.86-1.39,0,0,0-2.16,4.48-1.39a6.79,6.79,0,0,0-1.54-1.7,3.11,3.11,0,0,1,2.16.62,4.18,4.18,0,0,1,2.16-3.86l.31,3.86s2.6-3.06,2-2.47a6.77,6.77,0,0,0-.46,2.93,4.81,4.81,0,0,1,5.87.15s-4,.31-4.63,1.7c0,0,3.4-.62,4.17-.15,0,0-2.63.93-2.47,1.54,0,0,4.63,0,5.25,1.7,0,0-4.79.62-5.1,1.08,0,0,5.56,4,5.87,5.1,0,0-5.71-3.4-6.64-3.4s4.17,4.94,3.86,6.18C1023.3,270.08,1019.13,265.14,1018,265.45Z" transform="translate(-75 -47.08)" fill={ fill } />
    <path d="M926,271.65h0l-.52-2.78a1.51,1.51,0,0,0-.47-1.09l-2.41-1.18v0a2.73,2.73,0,0,1-.43-.72l0-.06a1.45,1.45,0,0,0,.28-.57,1,1,0,0,0,0-.16l0,0a.89.89,0,0,0,1-.72v-.06a.89.89,0,0,1-.81.67,2.46,2.46,0,0,0,1.09-2s0-.07,0-.1a.89.89,0,0,0,.41-.6v-.06a.89.89,0,0,1-.43.56,2.45,2.45,0,0,0-.15-.67h.06a.89.89,0,0,0,.89-.89s0,0,0-.06a.89.89,0,0,1-.89.83h-.11l0,0a1.46,1.46,0,0,0,1-1.19s0,0,0-.06a.89.89,0,0,1-.18.48.89.89,0,0,0-1.77-.26l-.16-.09a.89.89,0,0,1,.81-.65h-.06a.89.89,0,0,0-.86.6,2.46,2.46,0,0,0-3.1.89c-.19.14-.43.34-.42.51a1.7,1.7,0,0,0-.21.26,3.41,3.41,0,0,0-.28,1.85,2,2,0,0,1-.64,1.7.85.85,0,0,0,.66,0,2.51,2.51,0,0,0,.58-.34,1.29,1.29,0,0,0,.41-.39l0,0a2,2,0,0,0,.37.19c.05.42.06.9-.12,1.05l0,0h0l0-.08a2.26,2.26,0,0,1-1.57.45,2,2,0,0,0-1.62,1l-.75,3.21h0v.05h0v.05h0a.24.24,0,0,0,.18.18l.23.06A4.9,4.9,0,0,1,916,273a13.7,13.7,0,0,0-.27,2.36c.06.4.16,2.46.2,3.44a10.19,10.19,0,0,0-.2,1l-1.12,6.71s-.83,5.37-.79,7l-.15.94s-2.27,2.85-1.6,3.25,1.69.58,2.67-.94a2.45,2.45,0,0,1,.62-.94.92.92,0,0,0,.31-.78l-.07-.66.12,0v0l.07,0a23.18,23.18,0,0,0,1-6.24s2.07-7.38,3.65-8.54v0l.15-.07.34,7.14-.34,7.88-.07.47s-2.27,2.85-1.6,3.25,1.69.58,2.67-.94a2.45,2.45,0,0,1,.62-.94.92.92,0,0,0,.31-.78l-.1-1c.39-1.32,1.4-5.15,1-7.36l.69-4.71c.7-.78.6-2.77.6-2.77.55-1.14.67-5.64.67-5.64l.14-2.22.31,0h.11l0,0,.05-.06h0v-.05h0Zm-1.94,4.51c-.06-.15-.13-.31-.21-.46h-.06l0-.06,0-.05,0-.06a1.92,1.92,0,0,0,.08-.23.87.87,0,0,0,.13-.48A4.54,4.54,0,0,1,924.1,276.16Zm-.21-1.9-.14-1.49,0-.12.19,1.89Zm-6.59.2a.52.52,0,0,0-.12.4s0,0,0,0a1.69,1.69,0,0,0,.06.45l-.22.93,0-1.78a3.93,3.93,0,0,0,.37-.6A1,1,0,0,1,917.31,274.46Z" transform="translate(-75 -47.08)" fill="url(#3188e630-8ea4-4111-9509-7049548e0ddb)" />
    <path d="M918.71,263a3.28,3.28,0,0,0-.27,1.78,1.89,1.89,0,0,1-.62,1.64.82.82,0,0,0,.63,0,2.42,2.42,0,0,0,.56-.33,1.24,1.24,0,0,0,.39-.38,1.48,1.48,0,0,0,.09-.76,8,8,0,0,1,.12-1.35c0-.28.31-1,0-1.18S918.85,262.78,918.71,263Z" transform="translate(-75 -47.08)" fill="#72351c" />
    <path d="M914.12,292.51l-.21,1.37s-2.19,2.75-1.54,3.13,1.63.56,2.57-.9a2.36,2.36,0,0,1,.6-.9h0a.89.89,0,0,0,.3-.75l-.21-2.12Z" transform="translate(-75 -47.08)" fill="#333" />
    <path d="M920.71,293.06l-.21,1.37s-2.19,2.75-1.54,3.13,1.63.56,2.57-.9a2.36,2.36,0,0,1,.6-.9h0a.89.89,0,0,0,.3-.75l-.21-2.12Z" transform="translate(-75 -47.08)" fill="#333" />
    <path d="M917.3,276.45s-1,.74-1.41,3.33l-1.08,6.46a57,57,0,0,0-.74,7l1.82.62a22.33,22.33,0,0,0,1-6s2.19-7.79,3.72-8.34l.32,6.88-.34,7.76,1.56.31s1.58-4.93,1.08-7.54l.81-5.55a8.22,8.22,0,0,0-.36-5.53Z" transform="translate(-75 -47.08)" fill="#4d8af0" />
    <g opacity="0.1">
        <path d="M920.43,279.62v-.12c-1.53.55-3.72,8.34-3.72,8.34a21.88,21.88,0,0,1-.94,5.94l.2.07a22.33,22.33,0,0,0,1-6S918.91,280.74,920.43,279.62Z" transform="translate(-75 -47.08)" />
        <path d="M923.71,275.83l-.21,0a8.23,8.23,0,0,1,.35,5.51l-.81,5.55c.48,2.47-.92,7-1.06,7.5l.2,0s1.58-4.93,1.08-7.54l.81-5.55A8.22,8.22,0,0,0,923.71,275.83Z" transform="translate(-75 -47.08)" />
    </g>
    <path d="M915.9,271.39a.23.23,0,0,0,.18.23,3.6,3.6,0,0,1,1.38.66c.77.6-.81-1.2-.81-1.2l-.53.12-.22.19Z" transform="translate(-75 -47.08)" fill="#f55f44" />
    <path d="M915.9,271.39a.23.23,0,0,0,.18.23,3.6,3.6,0,0,1,1.38.66c.77.6-.81-1.2-.81-1.2l-.53.12-.22.19Z" transform="translate(-75 -47.08)" opacity="0.1" />
    <path d="M923.42,273a4.83,4.83,0,0,1,2.17-.86.2.2,0,0,0,.2-.2h0A1.73,1.73,0,0,0,923.42,273Z" transform="translate(-75 -47.08)" fill="#f55f44" />
    <path d="M925.79,271.91a1.73,1.73,0,0,0-2.37,1.06s1.34-1,2.27-.86" transform="translate(-75 -47.08)" opacity="0.1" />
    <circle cx="846.31" cy="216.46" r="2.37" fill="#72351c" />
    <path d="M919.67,265.2s.34,1.39,0,1.68-1,.81-.5,1.22,2.32,1.1,2.92.77,1.29-.24,1.13-.93-1.48-.55-1.51-4Z" transform="translate(-75 -47.08)" fill="#fdb797" />
    <path d="M916.32,271a8.67,8.67,0,0,1-.14,2.25,13.2,13.2,0,0,0-.26,2.27c.07.5.22,3.81.22,3.81s-.26,2.9,1.15,2.63-.22-3.3-.22-3.3l.07-4a3.69,3.69,0,0,0,.72-1.77C917.92,272,916.32,271,916.32,271Z" transform="translate(-75 -47.08)" opacity="0.1" />
    <path d="M916.29,271a8.67,8.67,0,0,1-.14,2.25,13.2,13.2,0,0,0-.26,2.27c.07.5.22,3.81.22,3.81s-.26,2.9,1.15,2.63-.22-3.3-.22-3.3l.07-4a3.69,3.69,0,0,0,.72-1.77C917.9,272,916.29,271,916.29,271Z" transform="translate(-75 -47.08)" fill="#fdb797" />
    <path d="M925.32,271.19l-.19,3.09s-.12,4.33-.65,5.43c0,0,.14,3-1.27,3s.24-3.33.24-3.33.76-3.38.37-4.46l-.37-3.71Z" transform="translate(-75 -47.08)" opacity="0.1" />
    <path d="M925.34,271.19l-.19,3.09s-.12,4.33-.65,5.43c0,0,.14,3-1.27,3s.24-3.33.24-3.33.76-3.38.37-4.46l-.37-3.71Z" transform="translate(-75 -47.08)" fill="#fdb797" />
    <path d="M920.75,268.19s1.14.53,1.77-1l2.33,1.14a1.45,1.45,0,0,1,.45,1.05l.5,2.68a1.71,1.71,0,0,0-2.23,1l.14,1.51h0a1.62,1.62,0,0,1-.07,1.13.25.25,0,0,0,0,.2.74.74,0,0,1-.67,1,14.12,14.12,0,0,0-1.87.14c-.5.14-2-.29-2.51,0s-1.56-.22-1.56-.22l.29-1.22s-.19-.6,0-.81a5.21,5.21,0,0,0-.12-2.61s-.86-.81-1.36-.67l.72-3.09a1.88,1.88,0,0,1,1.56-1,2.18,2.18,0,0,0,1.51-.43S919.07,268,920.75,268.19Z" transform="translate(-75 -47.08)" opacity="0.1" />
    <path d="M920.87,268.14c.31.08,1.15.18,1.65-1.08l2.33,1.14a1.45,1.45,0,0,1,.45,1.05l.5,2.68a1.71,1.71,0,0,0-2.23,1l.14,1.51.09.3a.85.85,0,0,1-.12.76.27.27,0,0,0,0,.28.74.74,0,0,1-.67,1,14.12,14.12,0,0,0-1.87.14c-.5.14-2-.29-2.51,0s-1.56-.22-1.56-.22l.29-1.22s-.19-.6,0-.81a5.21,5.21,0,0,0-.12-2.61s-.86-.81-1.36-.67l.72-3.09a1.88,1.88,0,0,1,1.56-1,2.18,2.18,0,0,0,1.51-.43s-.56,1,.92,1.25Z" transform="translate(-75 -47.08)" opacity="0.1" />
    <path d="M920.87,268.19c.31.08,1.15.18,1.65-1.08l2.33,1.14a1.45,1.45,0,0,1,.45,1.05l.5,2.68a1.71,1.71,0,0,0-2.23,1l.14,1.51h0a1.62,1.62,0,0,1-.07,1.13.25.25,0,0,0,0,.2.74.74,0,0,1-.67,1,14.12,14.12,0,0,0-1.87.14c-.5.14-2-.29-2.51,0s-1.56-.22-1.56-.22l.29-1.22s-.19-.6,0-.81a5.21,5.21,0,0,0-.12-2.61s-.86-.81-1.36-.67l.72-3.09a1.88,1.88,0,0,1,1.56-1,2.18,2.18,0,0,0,1.51-.43s-.56,1,.92,1.25Z" transform="translate(-75 -47.08)" fill="#f55f44" />
    <path d="M921.75,264.05l-2,1.27a6.61,6.61,0,0,1,.12.66,2,2,0,0,0,.73.14,2.05,2.05,0,0,0,1.32-.6A7.91,7.91,0,0,1,921.75,264.05Z" transform="translate(-75 -47.08)" opacity="0.1" />
    <circle cx="845.52" cy="216.96" r="1.96" fill="#fdb797" />
    <circle cx="848.42" cy="214.59" r="0.86" fill="#72351c" />
    <path d="M923.58,262.58a.86.86,0,0,1-.86-.8s0,0,0,.06a.86.86,0,1,0,1.72,0s0,0,0-.06A.86.86,0,0,1,923.58,262.58Z" transform="translate(-75 -47.08)" fill="#72351c" />
    <path d="M923.09,263.44a.86.86,0,0,1-.7-.94v.06a.86.86,0,0,0,1.7.31v-.06A.86.86,0,0,1,923.09,263.44Z" transform="translate(-75 -47.08)" fill="#72351c" />
    <path d="M922.42,265.5a.86.86,0,0,1-.7-.94v.06a.86.86,0,1,0,1.7.31v-.06A.86.86,0,0,1,922.42,265.5Z" transform="translate(-75 -47.08)" fill="#72351c" />
    <path d="M923.58,262.13a.86.86,0,0,1-.86-.8s0,0,0,.06a.86.86,0,0,0,1.72,0s0,0,0-.06A.86.86,0,0,1,923.58,262.13Z" transform="translate(-75 -47.08)" fill="#72351c" />
    <path d="M922.38,261.66a.86.86,0,0,1,.81-.85h-.06a.86.86,0,0,0,0,1.72h.06A.86.86,0,0,1,922.38,261.66Z" transform="translate(-75 -47.08)" fill="#72351c" />
    <path d="M923.25,269.79a3.1,3.1,0,0,1,0,1.29s-.12,1.13.07,1.32" transform="translate(-75 -47.08)" opacity="0.1" />
    <path d="M917.43,270a3.1,3.1,0,0,1,0,1.29s-.12,1.13.07,1.32" transform="translate(-75 -47.08)" opacity="0.1" />
    <path d="M917.38,274.67a.86.86,0,0,0,.57,0,1.2,1.2,0,0,1,.69,0" transform="translate(-75 -47.08)" opacity="0.1" />
    <path d="M917.67,273a5,5,0,0,0,2-.31s2.44.6,3.14.17" transform="translate(-75 -47.08)" opacity="0.1" />
    <rect x="840.9" y="236.21" width="1.51" height="0.93" rx="0.42" ry="0.42" fill="#fdb797" />
    <path d="M915.9,283.53l.81,0,.7.11h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M921.33,279.65l.81,0,.7.07h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M921.33,279.82l.81,0,.7.07h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M921.33,280l.81,0,.7.07h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M921.33,280.15l.81,0,.7.07h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M915.9,284.19l.81,0,.7.08h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M915.9,284.11l.81,0,.7.08h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M915.9,284l.81,0,.7.08h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M915.9,283.94l.81,0,.7.08h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M915.9,283.42l.81,0,.7.11h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M915.9,283.28l.81,0,.7.11h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M920.85,262.78a4.75,4.75,0,0,0,.27,2.16A2.58,2.58,0,0,1,921,267a.68.68,0,0,0,.61-.15,2.37,2.37,0,0,0,.44-.52,1.39,1.39,0,0,0,.27-.54,2.15,2.15,0,0,0-.14-.91,11.77,11.77,0,0,1-.29-1.62c0-.33,0-1.29-.37-1.38S920.92,262.5,920.85,262.78Z" transform="translate(-75 -47.08)" opacity="0.1" />
    <path d="M920.9,262.76a4.75,4.75,0,0,0,.27,2.16,2.58,2.58,0,0,1-.11,2.08.68.68,0,0,0,.61-.15,2.37,2.37,0,0,0,.44-.52,1.39,1.39,0,0,0,.27-.54,2.15,2.15,0,0,0-.14-.91,11.77,11.77,0,0,1-.29-1.62c0-.33,0-1.29-.37-1.38S921,262.48,920.9,262.76Z" transform="translate(-75 -47.08)" fill="#72351c" />
    <path d="M920.06,263.27c.16.17.2.43.34.63a1.23,1.23,0,0,0,1,.39.55.55,0,0,1,.28.06c.15.1.16.32.26.47a.55.55,0,0,0,.82,0A1.34,1.34,0,0,0,923,264a5.35,5.35,0,0,0,0-1,1.59,1.59,0,0,0-.44-1.06,1.84,1.84,0,0,0-.77-.33,2.28,2.28,0,0,0-1.19-.09,2.14,2.14,0,0,0-.54.27l-.7.47c-.26.18-.74.54-.27.74C919.43,263.07,919.81,263,920.06,263.27Z" transform="translate(-75 -47.08)" opacity="0.1" />
    <path d="M920.11,263.25c.16.17.2.43.34.63a1.23,1.23,0,0,0,1,.39.55.55,0,0,1,.28.06c.15.1.16.32.26.47a.55.55,0,0,0,.82,0,1.34,1.34,0,0,0,.31-.86,5.35,5.35,0,0,0,0-1,1.59,1.59,0,0,0-.44-1.06,1.84,1.84,0,0,0-.77-.33,2.28,2.28,0,0,0-1.19-.09,2.14,2.14,0,0,0-.54.27l-.7.47c-.26.18-.74.54-.27.74C919.48,263,919.85,263,920.11,263.25Z" transform="translate(-75 -47.08)" fill="#72351c" />
    <path d="M921.38,279.65l.81,0,.7.07h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M921.38,279.82l.81,0,.7.07h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M921.38,280l.81,0,.7.07h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M921.38,280.15l.81,0,.7.07h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M921,289.38l.81,0,.7.07h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M921,289.54l.81,0,.7.07h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M921,289.71l.81,0,.7.07h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M921,289.88l.81,0,.7.07h-.25Z" transform="translate(-75 -47.08)" fill="#f0e4e4" />
    <path d="M467.38,741.76H387.84V696.95h79.54ZM391.2,738.4H464V700.31H391.2Z" transform="translate(-75 -47.08)" fill={ fill } opacity="0.2" />
    <path d="M334.9,674.58c5,2.92,10.27,4.11,15.72,2.3a24.38,24.38,0,0,0,4.09-1.81c51,1.08,98.23.58,100.06-4.91,3-9.1-16-53.92-25.59-75.58a24.28,24.28,0,0,0,7-7.31c13.32,15.23,38.1,34.88,52.83,35.86,0,0,21.5,51.07,45.69,43s26.88-51.07,14.78-56.44l-80.63-51.07V473.88a31.74,31.74,0,0,0,3.37-15.7c0-1,.05-2,0-3l-2.69-24.19s-.14-.8-.51-2.25c-6-23.31-19.37-39-38.22-41.33a13.77,13.77,0,0,0,1.64-5.43c.39-4.52-1.49-9.13-.43-13.54,1.24-5.14,6-8.46,9.91-12,8.35-7.64,13.61-18,18.69-28.11,4.84-9.63,9.75-19.51,11-30.21s-2-22.58-10.75-28.88c-6.8-4.91-16.37-6.2-21.26-13-2.19-3.06-3.1-6.83-4.61-10.27-4.35-9.91-13.73-16.94-23.91-20.61-35.72-12.86-79.54,1.9-102.33,31.56-7.44,9.68-12.81,20.76-18.12,31.75l-19.2,39.69c-3.31,6.83-6.51,15.36-2.09,21.53,2.16,3,5.86,4.86,7.36,8.25,2,4.5-.5,10.39,2.44,14.35,1.62,2.19,4.43,3.06,6.84,4.34a26.78,26.78,0,0,1,8.27,7.33l-42.16,32.67a28.39,28.39,0,0,0-10.86,19.61L237.06,478a50.56,50.56,0,0,0-1.88,14.51c-.15,4.38.36,9.21,2.55,13l.92-.22c1,29.15,2.74,71.09,4.46,78.84,1,4.6,7.31,12.71,15.81,21.07l-3,8.5,1,0-1,2.67,2,0c-8.48,30.64-31.55,53.73-31.55,53.73S279.72,673,334.9,674.58ZM284.76,533V500.33c4.92.76,8.55,2.46,9.71,5.56ZM299,390.82l.16.24h-.06Z" transform="translate(-75 -47.08)" fill="url(#9ebb77c1-6d01-4561-8dcd-16473be994df)" />
    <path d="M420.31,465s2.55,90.62,5.11,102.11S465,613.1,484.13,614.38c0,0,20.42,48.5,43.4,40.84s25.53-48.5,14-53.61L465,553.11v-97Z" transform="translate(-75 -47.08)" fill="#fdb797" />
    <path d="M422.23,576.09s33.19,71.48,29.36,83-217,0-217,0,39.57-39.57,31.91-83Z" transform="translate(-75 -47.08)" fill="#565171" />
    <path d="M396.7,364.21s-5.11,49.78,3.83,61.27S364.79,443.34,361,443.34s-44.67-14-52.33-17.87-14-31.91-14-31.91,38.29,7.66,49.78-37Z" transform="translate(-75 -47.08)" fill="#fdb797" />
    <path d="M410.08,236.52c9.67,3.48,18.58,10.16,22.71,19.57,1.44,3.27,2.3,6.85,4.38,9.76,4.64,6.48,13.73,7.7,20.19,12.37,8.29,6,11.36,17.27,10.21,27.43s-5.82,19.55-10.41,28.69c-4.83,9.61-9.82,19.44-17.75,26.7-3.7,3.39-8.24,6.54-9.42,11.42-1,4.19.78,8.56.4,12.86-.92,10.61-13.6,15.55-24.19,16.73-4.6.51-9.87.54-13.11-2.77-1.66-1.7-2.49-4-3.55-6.14a29.43,29.43,0,0,0-52.49-.54c-2.48,4.78-3.93,10.57-8.45,13.49-6.92,4.47-16.17-.78-21.26-7.24s-8.8-14.58-16.07-18.45c-2.29-1.22-5-2-6.49-4.12-2.79-3.76-.42-9.35-2.31-13.63-1.43-3.22-4.94-5-7-7.84-4.2-5.86-1.16-14,2-20.45l18.24-37.7c5-10.44,10.15-21,17.21-30.16C334.53,238.32,376.15,224.3,410.08,236.52Z" transform="translate(-75 -47.08)" fill="#8f5a6a" />
    <path d="M363.52,434.41s-49.78,0-62.54-45.95l-41.9,32.47a27,27,0,0,0-10.31,18.63l-4,39.53s-4.47,14.68.64,23.61c0,0,53-13.4,54.25,1.91L262.68,608s57.44,1.28,72.75-8.93c0,0,62.54,12.76,77.86-1.28s30.63-12.76,17.87-44.67c0,0,25.53-25.53,14-61.27s-.64.64-.64.64,24.89-12.13,23.61-35.1l-2.55-23s-.14-.76-.49-2.13c-7.78-30.28-28.69-47-58.08-36.34-8.59,3.11-17.37-.45-17.37-.45S399.25,437,363.52,434.41Z" transform="translate(-75 -47.08)" opacity="0.1" />
    <path d="M363.52,431.86s-49.78,0-62.54-45.95l-41.9,32.47A27,27,0,0,0,248.77,437l-4,39.53s-4.47,14.68.64,23.61c0,0,53-13.4,54.25,1.91l-37,103.39s57.44,1.28,72.75-8.93c0,0,62.54,12.76,77.86-1.28s30.63-12.76,17.87-44.67c0,0,25.53-25.53,14-61.27s-.64.64-.64.64,24.89-12.13,23.61-35.1l-2.55-23s-.14-.76-.49-2.13c-7.78-30.28-28.69-47-58.08-36.34-8.59,3.11-17.37-.45-17.37-.45S399.25,434.41,363.52,431.86Z" transform="translate(-75 -47.08)" fill="#b45181" />
    <path d="M245.45,475.25s2.55,90.62,5.11,102.11,39.57,45.95,58.71,47.23c0,0,20.42,48.5,43.4,40.84s25.53-48.5,14-53.61l-76.58-48.5v-97Z" transform="translate(-75 -47.08)" fill="#fdb797" />
    <path d="M300.33,478.44S271,498.36,245.45,496.7c0,0-2.55-34.21,5.11-36.12S291.4,460.58,300.33,478.44Z" transform="translate(-75 -47.08)" opacity="0.1" />
    <path d="M300.33,477.17S271,497.09,245.45,495.42c0,0-2.55-34.21,5.11-36.12S291.4,459.3,300.33,477.17Z" transform="translate(-75 -47.08)" fill="#b45181" />
    <path d="M341.82,359.1l52.33,7.66s-1.18,11.52-1.41,24.75a58.74,58.74,0,0,1-61.59-10C335.53,376.11,339.31,368.85,341.82,359.1Z" transform="translate(-75 -47.08)" opacity="0.1" />
    <circle cx="297.45" cy="287.77" r="58.71" fill="#fdb797" />
    <path d="M357,323.57c2.42,1.12,5.18,1.14,7.85,1.12q15.58-.1,31.13-1.15c7.86-.53,16.24-1.45,22.3-6.48,8.16-6.77,9.45-19.46,5.24-29.19s-12.8-16.9-21.87-22.38A65.29,65.29,0,0,0,385.13,258c-16.83-4.57-35.44,1-48.81,12.25s-21.89,27.38-26.4,44.23c-1.56,5.83-3.56,12.77-9.33,14.54,3.84,6.39,13.21,7.4,19.95,4.2,9.92-4.72,15-22.78,23.91-25.62C354.36,304.47,348.58,319.71,357,323.57Z" transform="translate(-75 -47.08)" opacity="0.1" />
    <path d="M357,322.29c2.42,1.12,5.18,1.14,7.85,1.12q15.58-.1,31.13-1.15c7.86-.53,16.24-1.45,22.3-6.48,8.16-6.77,9.45-19.46,5.24-29.19s-12.8-16.9-21.87-22.38a65.29,65.29,0,0,0-16.49-7.44c-16.83-4.57-35.44,1-48.81,12.25s-21.89,27.38-26.4,44.23c-1.56,5.83-3.56,12.77-9.33,14.54,3.84,6.39,13.21,7.4,19.95,4.2,9.92-4.72,15-22.78,23.91-25.62C354.36,303.2,348.58,318.43,357,322.29Z" transform="translate(-75 -47.08)" fill="#8f5a6a" />
    <path d="M431.8,549.92a226.11,226.11,0,0,0-58.71-7.66" transform="translate(-75 -47.08)" opacity="0.1" />
    <path d="M300.33,481s19.15,14,14,21.7" transform="translate(-75 -47.08)" opacity="0.1" />
    <g opacity="0.7">
        <polygon points="754.58 578.64 127.88 578.64 127.88 633.43 164.36 633.43 164.36 644.13 164.36 805.84 719.4 805.84 719.4 644.13 719.4 633.43 754.58 633.43 754.58 578.64" fill="url(#589be0bd-308d-435b-8cf0-b9d21bd74c82)" />
    </g>
    <rect x="170" y="610.55" width="543.73" height="195.29" fill="#e0e0e0" />
    <rect x="170" y="620.76" width="543.73" height="30.63" opacity="0.1" />
    <rect x="134.26" y="588.85" width="613.94" height="52.33" fill="#e0e0e0" />
    <rect x="250.26" y="575.97" width="117.43" height="16.59" rx="6.5" ry="6.5" fill="#535461" />
    <g opacity="0.7">
        <rect x="321.73" y="390.97" width="291.85" height="201.8" rx="28.22" ry="28.22" fill="url(#3aa21ed4-97e3-4e91-81c1-d27551f27de1)" />
    </g>
    <rect x="326.94" y="394.7" width="276.47" height="198.07" rx="26.84" ry="26.84" fill="#3e3f49" />
    <rect x="330.77" y="394.7" width="276.47" height="198.07" rx="26.84" ry="26.84" fill="#535461" />
    <circle cx="481.92" cy="492.37" r="8.93" fill="#e0e0e0" />
</svg>
);

export default BloggingIcon;
